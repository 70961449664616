import { Box, SxProps, Typography } from "@mui/material";
import { useGameContext } from "GameContext";
import PlayerWait from "components/General/PlayerWait";
import Timer from "components/General/Timer";
import UploadCaption from "./UploadCaption";
import { getRandomCaption } from "Utils/randomCaption";
import { useFirestore } from "@services/firebase/firestore";

const Round2 = () => {
  const { setRoom } = useFirestore();
  const ROUND_TIME: number = parseInt(
    process.env.REACT_APP_ROUND_TIME || "90000"
  );
  const {
    game: { roomCode, isJudge, players, timerEnd, judge },
  } = useGameContext();

  if (!judge || !players || !timerEnd) {
    //Todo: HANDLE ERROR STATE
    return <div>ERRORR</div>;
  }

  const judgeName = players[judge].name;

  const onTimerComplete = async () => {
    const randomCaption = getRandomCaption();
    setRoom(roomCode!, {
      state: "ROUND2_IMG",
      timerEnd: Date.now() + ROUND_TIME,
      players: {
        [judge]: { caption: randomCaption },
      },
    });
  };

  return (
    <Box sx={styles.bodyBox}>
      <Timer
        timerEnd={timerEnd}
        onComplete={isJudge ? () => onTimerComplete() : undefined}
      />
      {isJudge ? (
        <UploadCaption />
      ) : (
        <PlayerWait judgeName={judgeName} gameState='ROUND2' />
      )}
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default Round2;
