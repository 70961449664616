import { Gif, Grid } from "@giphy/react-components";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import { SxProps } from "@mui/material/styles";
import { searchGifs } from "@services/giphy/giphy";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import Box from "@mui/material/Box";
import type { IGif } from "@giphy/js-types";
import theme from "theme";

interface GifGridProps {
  height: number;
  width: number;
  setImgId: (id: string) => void;
}

const GifGrid = ({ height, width, setImgId }: GifGridProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [gif, setGif] = useState<IGif>();

  return (
    <Box sx={{ ...styles.bodyContainer, height: height }}>
      <InputBase
        color='primary'
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon color='primary' sx={{ fontSize: "32px" }} />
          </InputAdornment>
        }
        sx={styles.searchInput}
        placeholder='Search Giphy'
        inputProps={{ "aria-label": "search google maps" }}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          gif && setGif(undefined);
        }}
      />
      <Box sx={styles.gridContainer}>
        {gif && <Gif gif={gif} width={width} noLink hideAttribution />}
        {!gif && (
          <Grid
            noLink
            hideAttribution
            onGifClick={(e) => {
              setGif(e);
              setImgId(e.id.toString());
            }}
            width={width}
            columns={2}
            key={searchTerm}
            fetchGifs={() => searchGifs(searchTerm, 0)}
          />
        )}
      </Box>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  searchInput: {
    color: "white",
    fontSize: "32px",
    borderStyle: "solid",
    borderColor: "white",
    borderRadius: "100px",
    padding: theme.spacing(2),
  },
  gridContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "white",
      borderRadius: "100px",
    },
  },
};
export default GifGrid;
