import { Box, SxProps, Typography } from "@mui/material";
import { Player, useGameContext } from "GameContext";
import CaptionImg from "./CaptionImg";
import Timer from "components/General/Timer";
import { useFirestore } from "@services/firebase/firestore";
import { getRandomCaption } from "Utils/randomCaption";
import { useEffect } from "react";
import RoundHeader from "components/General/RoundHeader";
import theme from "theme";

const Round1Caption = () => {
  const {
    game: {
      players,
      judge,
      isSpectator,
      isJudge,
      timerEnd,
      roomCode,
      currentUser,
    },
  } = useGameContext();
  const { setRoom } = useFirestore();

  useEffect(() => {
    if (isJudge && players) {
      const allCaptioned = Object.keys(players).every(
        (player) => player === judge || players[player].caption
      );

      if (allCaptioned) {
        setRoom(roomCode!, { state: "ROUND1_VOTE" });
      }
    }
  }, [isJudge, judge, players]);

  if (!timerEnd || !players || !judge || !currentUser) {
    //TODO HANDLE ERROR
    return <div>ERROR</div>;
  }

  const onTimerComplete = () => {
    if (isJudge) {
      let randomCaptionPlayers: { [uid: string]: Partial<Player> } = {};

      for (const key in players) {
        if (!players[key].caption && key !== judge) {
          const randomCaption = getRandomCaption();
          randomCaptionPlayers[key] = { caption: randomCaption };
        }
      }
      setRoom(roomCode!, {
        state: "ROUND1_VOTE",
        players: { ...players, ...randomCaptionPlayers },
      });
    }
  };

  const judgeName = players[judge].name;

  return (
    <Box sx={styles.bodyBox}>
      <Timer timerEnd={timerEnd} onComplete={onTimerComplete} />
      <CaptionImg
        isJudge={!!isJudge}
        isSpectator={!!isSpectator}
        timerEnd={timerEnd}
        judgeName={judgeName}
      />
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default Round1Caption;
