import { Box, SxProps, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import theme from "theme";

export interface PlayerAvatarProps {
  name?: string;
  icon?: "PERSON" | "CHECK";
  color?: string;
}

const PlayerAvatar = ({
  name,
  icon,
  color = theme.palette.secondary.main,
}: PlayerAvatarProps) => {
  return (
    <Box sx={styles.avatarBody}>
      {icon && icon === "PERSON" && <PersonIcon sx={{ color: color }} />}
      {icon && icon === "CHECK" && <CheckCircleOutlineIcon color='success' />}
      <Typography variant='body1' color='primary'>
        {name}
      </Typography>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  avatarBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default PlayerAvatar;
