import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import theme from "theme";

interface CollapsableAlertProps {
  text: string;
  severity: "error" | "warning" | "info" | "success";
  onClose: () => void;
}
const CollapsableAlert = ({
  text,
  severity,
  onClose,
}: CollapsableAlertProps) => {
  return (
    <Collapse in={!!text}>
      <Alert
        icon={false}
        variant='outlined'
        severity={severity}
        action={
          <IconButton
            aria-label='close'
            color='error'
            size='small'
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
        sx={{ mb: 2, borderColor: theme.palette.error.main }}
      >
        <AlertTitle sx={{ color: theme.palette.error.main }}>{text}</AlertTitle>
      </Alert>
    </Collapse>
  );
};

export default CollapsableAlert;
