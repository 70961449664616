import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "./firebase";

export const uploadFile = async (file: File, path: string) => {
  const storageRef = ref(storage, path);
  const snapshot = await uploadBytes(storageRef, file);
  return await getDownloadURL(snapshot.ref);
};

export const buildStorageRef = (
  roomCode: string,
  currentUser: string,
  state: string,
  fileName: string
) => `${roomCode}/${currentUser}/${state}.${fileName.split(".").pop()}`;
