const memeCaptions: string[] = [
  "When you finally understand adulting.",
  "Friday: Expectation vs. Reality.",
  "That face you make when the WiFi starts working again.",
  "When you see your crush in public.",
  "Trying to adult like...",
  "When you're on a diet, but someone mentions pizza.",
  "Me: I'll just have one more episode. Also me: It's 3 AM.",
  "When you hear your alarm on Monday morning.",
  "When your pet judges your life choices.",
  "When the group chat is on fire.",
  "When you successfully adult for a whole day.",
  "Me trying to avoid my responsibilities.",
  "When you find out the food is all gone.",
  "Trying to stay calm in 2023 like...",
  "When you realize it's only Wednesday.",
  "When you take a selfie vs. when someone else takes a pic of you.",
  "When you accidentally send a text to the wrong person.",
  "Trying to get out of bed on a cold morning.",
  "When you see a spider in your room.",
  "When you're the first one to arrive at the party.",
  "Me: I'm going to bed early tonight. Also me at 2 AM:",
  "When you open the fridge and find no food.",
  "When someone asks if you have any weekend plans.",
  "When you try to parallel park.",
  "Trying to save money like...",
  "When you see your ex with someone new.",
  "When you're in a meeting and can't stop laughing.",
  "When your phone autocorrects to something embarrassing.",
  "When your friend tells a bad joke, but you need their help.",
  "When you realize it's your birthday and no one remembered.",
  "When your alarm goes off and you contemplate your life choices.",
  "Trying to adult without coffee like...",
  "When you try to take a cute selfie and fail miserably.",
  "When you hear someone mention free food.",
  "Me: I'm on a diet. Also me:",
  "When you accidentally like someone's old post while stalking them.",
  "When you're at a family gathering and they ask about your love life.",
  "Trying to make healthy choices like...",
  "When your computer freezes and you haven't saved your work.",
  "When you see a funny meme in public and try not to laugh.",
  "When you successfully avoid spoilers for your favorite show.",
  "When your crush texts you first.",
  "When you're the DJ at the party and everyone loves your playlist.",
  "Trying to act natural when your crush walks by.",
  "When you're about to sneeze but it won't come out.",
  "When you realize you left your phone at home.",
  "When you finally finish a long book.",
  "Trying to find the motivation to exercise like...",
  "When your favorite song comes on the radio.",
  "When you realize it's the weekend and you have no plans.",
  "When you can't find your keys and you're already late.",
  "When someone eats the last slice of pizza.",
  "When you're stuck in traffic and just want to get home.",
  "When you see your food coming at a restaurant.",
  "When you get a new haircut and no one notices.",
  "When you're at a boring meeting and start daydreaming.",
  "When you're out of coffee and it's only Monday.",
  "When you accidentally like an old Instagram post while stalking someone.",
  "When you're waiting for a text and your phone vibrates, but it's just a notification.",
  "When you're trying to take a nap, but someone keeps making noise.",
  "When you finally finish a DIY project and it actually looks good.",
  "When you realize you left your lunch at home.",
  "When you're trying to make a decision and your friends give conflicting advice.",
  "When you're at the gym and you see someone eating junk food.",
  "When you're at a party and the music is too loud.",
  "When you can't remember if you locked the door.",
  "When you're on a video call and your camera freezes on an awkward face.",
  "When you're trying to impress someone and you spill food on yourself.",
  "When you're trying to save money but end up online shopping.",
  "When you're at the movies and someone won't stop talking.",
  "When you're the first one to arrive at a social event.",
  "When you're trying to read a book, but your phone keeps distracting you.",
  "When you're waiting for your food delivery and it's taking forever.",
  "When you're on hold with customer service and they keep playing the same song.",
  "When you're trying to be healthy, but someone offers you dessert.",
  "When you're at a concert and the person in front of you is too tall.",
  "When you accidentally send a message to the wrong group chat.",
  "When you're at a party and don't know anyone.",
  "When you're at the DMV and the line never seems to move.",
  "When you're trying to take a selfie and the lighting is just right.",
  "When you're trying to clean your room but end up finding old photos.",
  "When you're at a restaurant and can't decide what to order.",
  "When you're at the beach and a seagull steals your food.",
  "When you're trying to study but keep getting distracted.",
  "When you're on a road trip and the GPS takes you the wrong way.",
  "When you're trying to start a new hobby but can't find the motivation.",
  "When you're at the gym and the person next to you is lifting way more weight.",
  "When you're trying to cook a new recipe and it turns into a disaster.",
  "When you're at a party and the food is gone in 5 minutes.",
  "When you're trying to take a nap but the neighbors are being loud.",
  "When you're waiting for a package and it gets delayed.",
  "When you're trying to make plans with your friends but no one responds.",
  "When you're at a concert and the opening act is better than the main act.",
  "When you're trying to impress someone but trip and fall.",
  "When you're at a wedding and catch the bouquet.",
  "When you're trying to lose weight but someone brings donuts to the office.",
  "When you're at a job interview and they ask about your weaknesses.",
  "When you're trying to be productive but end up binge-watching a TV show.",
  "When you're at the store and forget what you came to buy.",
  "When you're trying to be quiet but accidentally make a loud noise.",
  "When you're at a family reunion and have to explain your job for the hundredth time.",
  "When you're trying to take a photo of your food but the lighting is terrible.",
  "When you're trying to impress your date but spill wine on the tablecloth.",
  "When you're at a party and someone starts a political debate.",
  "When you're trying to find your way in a new city and get lost.",
  "When you're trying to be environmentally friendly but forget your reusable bag.",
  "When you're at a concert and someone keeps pushing you from behind.",
  "When you're trying to study and your neighbor is having a loud party.",
  "When you're at a job interview and they ask where you see yourself in 5 years.",
  "When you're trying to be healthy but the salad has more calories than a burger.",
  "When you're at a movie theater and the person next to you talks throughout the film.",
  "When you're trying to take a selfie but the front camera is on.",
  "When you're at a wedding and they play your favorite song.",
  "When you're trying to eat quietly but your food is too crunchy.",
  "When you're at a party and the host's pet won't leave you alone.",
  "When you're trying to have a serious conversation but can't stop laughing.",
  "When you're at a restaurant and the waiter forgets your order.",
  "When you're trying to sleep but your neighbor's dog won't stop barking.",
  "When you're at a job interview and they ask about your strengths.",
  "When you're trying to be organized but can't find your keys.",
  "When you're at a concert and your favorite band plays their biggest hit.",
  "When you're trying to eat a healthy snack but end up eating the whole bag.",
  "When you're at a family gathering and have to explain technology to your grandparents.",
  "When you're trying to take a selfie with a celebrity but it turns out blurry.",
  "When you're at a party and the music suddenly changes to your favorite genre.",
  "When you're trying to be productive but your cat won't stop sitting on your keyboard.",
  "When you're at a job interview and they ask about your previous experience.",
  "When you're trying to eat a salad but the lettuce is wilted.",
  "When you're at a movie theater and the person in front of you blocks your view.",
  "When you're trying to take a nap but someone mows their lawn outside.",
  "When you're at a restaurant and the waiter keeps refilling your water glass.",
  "When you're trying to study but your favorite show releases a new season.",
  "When you're at a party and someone spills a drink on your favorite shirt.",
  "When you're trying to impress someone but forget their name.",
  "When you're at a wedding and the cake is even better than it looks.",
  "When you're trying to eat a healthy breakfast but end up with pancakes.",
  "When you're at a family gathering and your relatives start arguing at the dinner table.",
  "When you're trying to take a selfie but the lighting in the room is terrible.",
  "When you're at a job interview and they ask about your dream job.",
  "When you're trying to be on time but get stuck in traffic.",
  "When you're trying to be productive but keep getting distracted by social media.",
  "When you're at the store and the checkout line is super long.",
  "When you're trying to make plans but your friends keep canceling.",
  "When you're at a concert and the crowd sings along with the band.",
  "When you're trying to eat quietly but your stomach starts growling.",
  "When you're at a party and someone brings out a karaoke machine.",
  "When you're trying to impress someone but trip over your own feet.",
  "When you're at a wedding and the bride and groom dance their first dance.",
  "When you're trying to lose weight but can't resist dessert.",
  "When you're at a job interview and they ask about your greatest achievement.",
  "When you're trying to be productive but your cat keeps knocking things off the table.",
  "When you're at a family gathering and your relatives start telling embarrassing stories about you.",
  "When you're trying to take a selfie but your phone battery dies.",
  "When you're at a restaurant and the waiter takes forever to bring the check.",
  "When you're trying to study but your neighbor is playing loud music.",
  "When you're at a job interview and they ask why you want to work for their company.",
  "When you're trying to be healthy but the salad dressing is loaded with calories.",
  "When you're at a movie theater and someone behind you kicks your seat.",
  "When you're trying to take a nap but the construction work next door is too loud.",
  "When you're at a party and the host runs out of snacks.",
  "When you're trying to impress someone but spill your drink on them.",
  "When you're at a wedding and the couple reads their heartfelt vows.",
  "When you're trying to eat a healthy lunch but your coworker brings in donuts.",
  "When you're at a family gathering and your relatives start asking about your love life.",
  "When you're trying to take a selfie but the camera is in selfie mode.",
  "When you're at a restaurant and the table next to you gets their food first.",
  "When you're trying to study but your computer crashes and you lose all your work.",
  "When you're at a job interview and they ask about your long-term career goals.",
  "When you're trying to be productive but keep getting distracted by YouTube.",
  "When you're at the store and they don't have your favorite snack in stock.",
  "When you're trying to make plans but everyone has different availability.",
  "When you're at a concert and the band plays an encore.",
  "When you're trying to eat a healthy dinner but your friend orders pizza.",
  "When you're at a family gathering and your relatives start discussing politics.",
  "When you're trying to take a selfie but your phone camera is blurry.",
  "When you're at a job interview and they ask about your leadership experience.",
  "When you're trying to be on time but can't find your car keys.",
  "When you're at a movie theater and someone talks loudly on their phone.",
  "When you're trying to take a nap but someone starts mowing the lawn.",
  "When you're at a party and the host's dog steals your food.",
  "When you're trying to impress someone but accidentally insult them.",
  "When you're at a wedding and the couple shares their first dance.",
  "When you're trying to eat a healthy snack but your coworker brings in cookies.",
  "When you're at a family gathering and your relatives start telling embarrassing stories about themselves.",
  "When you're trying to take a selfie but your phone battery dies just before you snap the pic.",
  "When you're at a restaurant and the waiter takes forever to bring the bill.",
  "When you're trying to study but your neighbor's loud party keeps you up all night.",
  "When you're at a job interview and they ask why you're the best fit for the position.",
  "When you're trying to be healthy but the salad bar is out of your favorite toppings.",
  "When you're at a movie theater and someone kicks your seat repeatedly.",
  "When you're trying to take a nap but your neighbor starts drilling next door.",
  "When you're at a party and the host runs out of drinks.",
];

export const getRandomCaption = () => {
  return memeCaptions[Math.floor(Math.random() * memeCaptions.length)];
};
