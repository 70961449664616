import {
  Container,
  InputBaseComponentProps,
  SxProps,
  TextField,
} from "@mui/material";
import React from "react";

interface TextInputProps {
  label: string;
  onChange: (text: string) => void;
  autoFocus?: boolean;
  multiline?: boolean;
  style?: React.CSSProperties | InputBaseComponentProps;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  onChange,
  autoFocus = false,
  multiline = false,
  style,
}) => {
  return (
    <Container sx={styles.inputContainer}>
      <TextField
        sx={multiline ? styles.multiLineInput : null}
        autoFocus={autoFocus}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        required
        variant='standard'
        multiline={multiline}
        InputLabelProps={{
          style: {
            color: "white",
          },
        }}
        inputProps={{
          maxLength: process.env.REACT_APP_MAX_INPUT_LENGTH,
          style: {
            color: "white",
            fontSize: multiline ? "2rem" : "1.5rem",
            ...style,
          },
        }}
      />
    </Container>
  );
};

const styles: Record<string, SxProps> = {
  inputContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: "2rem",
    flexDirection: "column",
  },
  multiLineInput: {
    width: "100%",
    ".MuiInputBase-root": {
      fontSize: "2rem",
    },
  },
};

export default TextInput;
