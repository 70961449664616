import { useAuth } from "@services/firebase/authentication";
import { useFirestore } from "@services/firebase/firestore";
import { GameState } from "components/General/GameContainer";
import { User } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

export type PlayerType = "HOST" | "PLAYER" | "SPECTATOR" | "UNKNOWN";
export type ImgType = "PHOTO_LIBRARY" | "GIF";

export type Player = {
  name: string;
  hasJudged: boolean;
  imgURI: string;
  imgType: ImgType;
  caption: string;
  vote: string;
  totalPoints: number;
  roundPoints: number;
};

export type PlayerObj = {
  [uid: string]: Player;
};

export interface Game {
  host: string;
  name: string;
  currentUser: string | null;
  roomCode: string;
  judge: string;
  state: GameState;
  players: PlayerObj;
  timerEnd: number;
  isHost: boolean;
  isSpectator: boolean;
  isJudge: boolean;
}

type GameContextType = {
  game: Partial<Game>;
  setGame: (game: Partial<Game>) => void;
  currentUser: User | null;
};

export const GameContext = createContext<GameContextType>({
  game: {},
  setGame: (game: Partial<Game>) => {},
  currentUser: null,
});

interface GameProviderProps {
  children: React.ReactNode;
}

export const GameProvider = ({ children }: GameProviderProps) => {
  const [game, setGame] = useState<Partial<Game>>({});
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { authListener } = useAuth();
  const { userListener, roomListener } = useFirestore();

  useEffect(() => {
    if (currentUser) {
      userListener(currentUser.uid, (data) => {
        setGame({
          ...game,
          roomCode: data["roomCode"],
          currentUser: currentUser.uid,
          isSpectator: data["spectator"],
        });
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (game.roomCode) {
      roomListener(game.roomCode, (data) => {
        const players: PlayerObj = data["players"];
        setGame({
          roomCode: game.roomCode,
          state: data["state"],
          players: players,
          judge: data["judge"],
          host: data["host"],
          timerEnd: data["timerEnd"],
          isHost: data["host"] === currentUser?.uid,
          isJudge: data["judge"] === currentUser?.uid,
          ...game,
        });
      });
    }
  }, [game.roomCode]);

  useEffect(() => {
    authListener({ handleAuthChange: (user) => setCurrentUser(user) });
  }, [authListener]);

  return (
    <GameContext.Provider value={{ game, setGame, currentUser }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => useContext(GameContext);
