import { Slide, SxProps, Typography } from "@mui/material";

interface SlideTextProps {
  direction: "right" | "left";
  text: string;
  inCondition: boolean;
  sx?: SxProps;
}

const SlideText = ({ inCondition, direction, text, sx }: SlideTextProps) => {
  return (
    <Slide in={inCondition} direction={direction}>
      <Typography variant='h3' color='primary' sx={sx}>
        {text}
      </Typography>
    </Slide>
  );
};

export default SlideText;
