import { Box, SxProps, Typography } from "@mui/material";
import { useFirestore } from "@services/firebase/firestore";
import { ImgType, useGameContext } from "GameContext";
import SlideImage from "components/General/SlideImage";
import VoteBox from "components/General/VoteBox";
import { useEffect, useState } from "react";
import theme from "theme";
import RoundHeader from "components/General/RoundHeader";

type ImageContent = { imgURI: string; imgType: ImgType; user: string };

const Round2Vote = () => {
  const [images, setImages] = useState<ImageContent[]>([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [voted, setVoted] = useState(false);
  const [slideDirection, setSlideDirection] = useState<"left" | "right">(
    "right"
  );
  const { setRoom } = useFirestore();
  const {
    game: { players, currentUser, roomCode, isSpectator, isHost, judge },
  } = useGameContext();

  useEffect(() => {
    setImages(
      Object.entries(players!)
        .filter(([user, player]) => !!player.imgURI && user !== currentUser)
        .map(([user, player]) => ({
          imgURI: player.imgURI,
          imgType: player.imgType,
          user: user,
        }))
    );
  }, [currentUser]);

  useEffect(() => {
    if (isHost && players) {
      const allVoted = Object.values(players).every((player) => player.vote);

      if (allVoted) {
        setRoom(roomCode!, { state: "ROUND2_SCORE" });
      }
    }
    if (players && currentUser && players[currentUser].vote) {
      setVoted(true);
    }
  }, [currentUser, isHost, players, roomCode, setRoom]);

  const submitVote = () => {
    const votedPlayerId = images[imageIndex].user;

    if (roomCode && currentUser) {
      setRoom(roomCode, {
        players: {
          [currentUser]: { vote: votedPlayerId },
        },
      }).catch((err) => console.log(err));
    }
  };
  if (!players || !judge) {
    //TODO: HANDLE ERROR STATE
    return <div>ERROR</div>;
  }
  return (
    <Box sx={styles.bodyBox}>
      <RoundHeader>
        <>
          <span style={{ color: theme.palette.primary.main }}>
            {players[judge].name}{" "}
          </span>
          is the judge
        </>
      </RoundHeader>
      {images.map((image, index) => (
        <Box sx={imageIndex === index ? undefined : styles.hiddenImage}>
          <SlideImage
            inCondition={imageIndex === index}
            direction={slideDirection}
            imgURI={image.imgURI}
            imgType={image.imgType}
          />
        </Box>
      ))}
      <Typography variant='h3' color='primary' sx={styles.captionText}>
        {players[judge].caption}
      </Typography>
      <VoteBox
        showWaiting={voted || !!isSpectator}
        submitVote={submitVote}
        disableGoLeft={imageIndex === 0}
        disableGoRight={imageIndex === images.length - 1}
        onLeftClick={() => {
          setSlideDirection("right");
          setImageIndex(imageIndex - 1);
        }}
        onRightClick={() => {
          setSlideDirection("left");
          setImageIndex(imageIndex + 1);
        }}
      />
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  hiddenImage: {
    display: "none",
  },
  captionText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
};

export default Round2Vote;
