import { Box, SxProps, Typography } from "@mui/material";
import PlayerAvatar, {
  PlayerAvatarProps,
} from "components/General/PlayerAvatar";
import React from "react";
import theme from "theme";

interface ScoreBoardItemProps {
  name: string;
  totalPoints: number;
  roundPoints: number;
  content: React.ReactNode;
  voterAvatars: PlayerAvatarProps[];
}

const ScoreBoardItem = ({
  name,
  totalPoints,
  roundPoints,
  content,
  voterAvatars,
}: ScoreBoardItemProps) => {
  return (
    <Box sx={styles.bodyBox}>
      <Box sx={styles.columnBox}>
        <Typography variant='h4' color='secondary'>
          {name}
        </Typography>
        <Box sx={styles.totalPointsBox}>
          <Typography
            variant='h3'
            color='primary'
            sx={{ marginRight: theme.spacing(1) }}
          >
            {totalPoints}
          </Typography>
          <Typography variant='h5' color='primary'>
            pts
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.columnBox2}>
        <Box sx={styles.roundText}>
          <Typography
            variant='body1'
            color='primary'
            sx={{ marginRight: theme.spacing(1) }}
          >
            Round:
          </Typography>
          <Typography variant='h5' color='secondary'>
            {roundPoints} pts
          </Typography>
        </Box>
        <Box sx={styles.avatarListBox}>
          {voterAvatars.map((avatar, key) => (
            <Box sx={styles.playerAvatarBox}>
              <PlayerAvatar
                key={key}
                name={avatar.name}
                icon={avatar.icon}
                color={avatar.color}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={styles.columnBox}>{content}</Box>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[500],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  columnBox: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    width: "100%",
    flexGrow: 1,
    flexBasis: 0,
  },
  columnBox2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "auto",
    flexGrow: 1,
    flexBasis: 0,
    paddingRight: theme.spacing(1),
  },
  totalPointsBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  roundText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarListBox: {
    justifyContent: "center",
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexBasis: 0,
  },
  playerAvatarBox: {
    marginRight: theme.spacing(1),
  },
};

export default ScoreBoardItem;
