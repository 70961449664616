import { GiphyFetch } from "@giphy/js-fetch-api";

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY || "");

// configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
export const searchGifs = (term: string, offset: number) =>
  gf.search(term, { offset, limit: 100 });

export const getRandomGif = () => gf.random();

export const getGifById = (id: string) => gf.gif(id);
