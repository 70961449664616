import {
  doc,
  DocumentData,
  onSnapshot,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "@services/firebase/firebase";

export type UseDocData = (docData: DocumentData) => void;
export type HandleDocError = () => void;

export const ROOM_PATH = "rooms/";
export const USER_PATH = "users/";

export const useFirestore = () => {
  const writeDoc = async (path: string, data: Record<string, any>) => {
    const docRef = doc(db, path);
    await setDoc(docRef, data, { merge: true });
  };

  const retrieveDoc = async (
    path: string,
    docTransform: UseDocData,
    onError?: HandleDocError
  ) => {
    const docRef = doc(db, path);
    return getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          docTransform(doc.data());
        } else {
          onError && onError();
        }
      })
      .catch(() => {
        onError && onError();
      });
  };

  const docListener = async (
    path: string,
    docTransform: UseDocData,
    onError?: HandleDocError
  ) => {
    const docRef = doc(db, path);
    return onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        docTransform(doc.data());
      } else {
        onError && onError();
      }
    });
  };

  const setRoom = async (roomCode: string, data: Record<string, any>) =>
    writeDoc(ROOM_PATH + roomCode, data);

  const setUser = async (userId: string, data: Record<string, any>) =>
    writeDoc(USER_PATH + userId, data);

  const userListener = async (
    userId: string,
    useDocData: UseDocData,
    onError?: HandleDocError
  ) => docListener(USER_PATH + userId, useDocData, onError);

  const roomListener = async (
    roomCode: string,
    useDocData: UseDocData,
    onError?: HandleDocError
  ) => docListener(ROOM_PATH + roomCode, useDocData, onError);

  const getRoom = async (
    roomCode: string,
    useDocData: UseDocData,
    onError?: HandleDocError
  ) => retrieveDoc(ROOM_PATH + roomCode, useDocData, onError);

  return { setRoom, setUser, userListener, roomListener, getRoom };
};
