import { useEffect, useState } from "react";

const MOBILE_DEVICE_MAX_WIDTH = 768;
const TABLED_DEVICE_MAX_WIDTH = 1024;
export const MAX_CONTENT_WIDTH = 700;
export const CONTENT_MARGINS = 48;

export const useUserDevice = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [screenWidth, setScreenWidth] = useState(
    window.innerWidth - CONTENT_MARGINS
  );

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      innerWidth > MAX_CONTENT_WIDTH
        ? setScreenWidth(MAX_CONTENT_WIDTH)
        : setScreenWidth(innerWidth - CONTENT_MARGINS);

      if (innerWidth < MOBILE_DEVICE_MAX_WIDTH) {
        setIsMobile(true);
        setIsTablet(false);
        setIsDesktop(false);
      } else if (
        innerWidth >= MOBILE_DEVICE_MAX_WIDTH &&
        innerWidth < TABLED_DEVICE_MAX_WIDTH
      ) {
        setIsMobile(false);
        setIsTablet(true);
        setIsDesktop(false);
      } else {
        setIsMobile(false);
        setIsTablet(false);
        setIsDesktop(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isMobile, isTablet, isDesktop, screenWidth };
};
