import { SxProps, Typography } from "@mui/material";
import theme from "theme";

interface RoundHeaderProps {
  children: React.ReactNode;
}

const RoundHeader = ({ children }: RoundHeaderProps) => {
  return (
    <Typography variant='h5' color='secondary' sx={styles.headerText}>
      {children}
    </Typography>
  );
};

const styles: Record<string, SxProps> = {
  headerText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
};

export default RoundHeader;
