import { Box, SxProps } from "@mui/material";
import { Player, useGameContext } from "GameContext";
import PlayerAvatar from "./PlayerAvatar";
import theme from "theme";

interface PlayerAggregateProps {
  field: keyof Player;
}

const PlayerAggregate = ({ field }: PlayerAggregateProps) => {
  const {
    game: { players },
  } = useGameContext();

  if (!players) {
    return <div>ERROR</div>;
  }

  return (
    <Box sx={styles.bodyBox}>
      {players &&
        Object.keys(players).map((player) => {
          if (players[player][field]) {
            return (
              <Box sx={styles.playerAvatarBox}>
                <PlayerAvatar name={players[player].name} icon='CHECK' />
              </Box>
            );
          }
          return null;
        })}
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "row",
  },
  playerAvatarBox: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
};

export default PlayerAggregate;
