import { Box, Button, Container, SxProps, Typography } from "@mui/material";
import { useUserDevice, MAX_CONTENT_WIDTH } from "Hooks/useUserDevice";
import RoomCode from "./RoomCode";
import { GameState } from "./GameContainer";
import { useNavigate } from "react-router-dom";
import { signOut } from "@services/firebase/authentication";
import { useGameContext } from "GameContext";

interface PageWrapperProps {
  children: React.ReactNode;
  roomCode?: string;
  gameState?: GameState;
}

const PageWrapper = ({ children, roomCode, gameState }: PageWrapperProps) => {
  const { isMobile } = useUserDevice();
  const navigate = useNavigate();
  const { setGame } = useGameContext();

  const maxWidth = isMobile ? "100%" : `${MAX_CONTENT_WIDTH}px`;

  const getRoundText = () => {
    switch (gameState) {
      case "ROUND1":
      case "ROUND1_CAPTION":
      case "ROUND1_VOTE":
      case "ROUND1_SCORE":
        return "Round 1";
      case "ROUND2":
      case "ROUND2_IMG":
      case "ROUND2_VOTE":
      case "ROUND2_SCORE":
        return "Round 2";
      default:
        return "";
    }
  };

  const signOutPlayer = () => {
    signOut()?.then(() => {
      setGame({});
      navigate("/");
    });
  };

  return (
    <Container sx={styles.pageContainer}>
      {roomCode && (
        <Box sx={styles.headerBox}>
          <Button variant='text' color='error' onClick={signOutPlayer}>
            Exit
          </Button>
          <Typography variant={"h6"} color='primary'>
            {getRoundText()}
          </Typography>
          {gameState !== "LOBBY" && (
            <Box sx={styles.roomCodeBox}>
              <RoomCode
                roomCode={roomCode}
                variant='h6'
                grayScale={!!roomCode}
              />
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ ...styles.contentBox, width: maxWidth }}>{children}</Box>
      {
        //TODO: add footer
      }
    </Container>
  );
};

const styles: Record<string, SxProps> = {
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
  },
  headerBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  // roomCodeBox: {
  //   width: "100%",
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   // borderBottom: "1px solid white",
  // },
};

export default PageWrapper;
