import { Box, SxProps, Typography } from "@mui/material";
import { GameState } from "components/General/GameContainer";
import theme, { animations } from "theme";

interface PlayerWaitProps {
  judgeName: string;
  gameState?: GameState;
}

const PlayerWait = ({ judgeName, gameState }: PlayerWaitProps) => {
  const getPrimaryText = () => {
    switch (gameState) {
      case "ROUND2":
        return (
          <>
            <span style={{ color: theme.palette.primary.main }}>
              {judgeName}
            </span>{" "}
            has until the timer runs out to create a{" "}
            <span style={{ color: theme.palette.primary.main }}>caption</span>
          </>
        );
      default:
        return (
          <>
            <span style={{ color: theme.palette.primary.main }}>
              {judgeName}
            </span>{" "}
            has until the timer runs out to upload a{" "}
            <span style={{ color: theme.palette.primary.main }}>photo</span> or{" "}
            <span style={{ color: theme.palette.primary.main }}>gif</span>.
          </>
        );
    }
  };

  const getSecondaryText = () => {
    switch (gameState) {
      case "ROUND2":
        return (
          <>
            Once the caption is uploaded, you will need to find a photo or gif
            that matches the caption better than the other players.
          </>
        );
      default:
        return (
          <>
            Once the image is uploaded, you will need to come up with the best
            caption that beats all the other players!
          </>
        );
    }
  };

  return (
    <Box sx={styles.bodyContainer}>
      <Typography variant='h3' color='secondary' sx={styles.centerText}>
        {getPrimaryText()}
      </Typography>
      <Typography variant='h5' color='secondary' sx={styles.secondaryText}>
        {getSecondaryText()}
      </Typography>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerText: {
    textAlign: "center",
  },
  secondaryText: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
    ...animations.pulse,
  },
};

export default PlayerWait;
