import { ImgType, useGameContext } from "GameContext";
import UploadContainer from "./UploadContainer";
import PlayerWait from "../General/PlayerWait";
import { Box, SxProps, Typography } from "@mui/material";
import Timer from "components/General/Timer";
import { getRandomGif } from "@services/giphy/giphy";
import { useFirestore } from "@services/firebase/firestore";
import { useState } from "react";
import CollapsableAlert from "components/General/CollapsableAlert";
import theme from "theme";
import RoundHeader from "components/General/RoundHeader";

const Round1 = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const {
    game: { judge, isJudge, players, roomCode, currentUser, timerEnd },
  } = useGameContext();
  const { setRoom } = useFirestore();
  const ROUND_TIME: number = parseInt(
    process.env.REACT_APP_ROUND_TIME || "90000"
  );

  if (!judge || !players || !timerEnd) {
    //Todo: HANDLE ERROR STATE
    return <div>ERRORR</div>;
  }

  const judgeName = players[judge].name;

  const onTimerComplete = async () => {
    const randomGif = await getRandomGif();
    let imgType: ImgType = "GIF";
    setRoom(roomCode!, {
      state: "ROUND1_CAPTION",
      timerEnd: Date.now() + ROUND_TIME,
      players: {
        [currentUser!]: { imgURI: randomGif.data.id, imgType: imgType },
      },
    });
  };

  const setImgURI = (imgURI: string, imgType: ImgType) => {
    if (roomCode && currentUser) {
      setRoom(roomCode, {
        state: "ROUND1_CAPTION",
        timerEnd: Date.now() + ROUND_TIME,
        players: { [currentUser]: { imgURI: imgURI, imgType: imgType } },
      });
    } else {
      setErrorMessage("Error fetching game context please refresh the page");
    }
  };

  return (
    <Box sx={styles.bodyBox}>
      <Timer
        timerEnd={timerEnd}
        onComplete={isJudge ? () => onTimerComplete() : undefined}
      />
      {isJudge ? (
        <UploadContainer onUpload={setImgURI} onError={setErrorMessage} />
      ) : (
        <PlayerWait judgeName={judgeName} gameState='ROUND1' />
      )}
      {errorMessage && (
        <Box sx={styles.errorMessage}>
          <CollapsableAlert
            severity='error'
            text={errorMessage}
            onClose={() => setErrorMessage("")}
          />
        </Box>
      )}
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  errorMessage: {
    marginTop: theme.spacing(3),
  },
};

export default Round1;
