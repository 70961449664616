import { Gif } from "@giphy/react-components";
import { Box } from "@mui/material";
import { useUserDevice } from "Hooks/useUserDevice";
import type { IGif } from "@giphy/js-types";
import { useEffect, useState } from "react";
import { ImgType } from "GameContext";
import { getGifById } from "@services/giphy/giphy";
import theme from "theme";

interface ImgDisplayProps {
  imgURI: string;
  imgType: ImgType;
  width?: number;
}

const ImgDisplay = ({ imgURI, imgType, width }: ImgDisplayProps) => {
  const [gif, setGif] = useState<IGif>();
  const { screenWidth } = useUserDevice();
  useEffect(() => {
    const getGif = async () => {
      const fetchedGif = await getGifById(imgURI);
      setGif(fetchedGif.data);
    };
    if (imgType === "GIF") {
      getGif();
    }
  }, []);

  if (gif) {
    return (
      <Box
        sx={{
          borderStyle: "solid",
          borderWidth: "3px",
          borderRadius: theme.spacing(1),
          borderColor: theme.palette.primary.main,
          width: width || screenWidth,
        }}
      >
        <Gif gif={gif} width={width || screenWidth} noLink hideAttribution />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          borderStyle: "solid",
          borderWidth: "3px",
          borderRadius: theme.spacing(1),
          borderColor: theme.palette.primary.main,
          width: width || screenWidth,
        }}
      >
        <img
          src={imgURI}
          alt='caption-img'
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    );
  }
};

export default ImgDisplay;
