import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface TimerProps {
  timerEnd: number;
  onComplete?: () => void;
}

const Timer = ({ timerEnd, onComplete }: TimerProps) => {
  const [timer, setTimer] = useState<number>(
    timerEnd ? Math.floor((timerEnd - Date.now()) / 1000) : 0
  );
  useEffect(() => {
    const interval = setInterval(async () => {
      if (timerEnd) {
        const remaining = Math.floor((timerEnd - Date.now()) / 1000);

        if (remaining <= 0) {
          clearInterval(interval);
          setTimer(0);
          onComplete && onComplete();
        } else {
          setTimer(remaining);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <Typography variant='h2' color='primary'>
      {timer}
    </Typography>
  );
};

export default Timer;
