import { useRef, useState } from "react";
import uploadImg from "../../assets/svg/uploadArrow.svg";
import Container from "@mui/material/Container";
import { useUserDevice } from "Hooks/useUserDevice";

interface ImgUploadProps {
  onFileChange: (file: File) => void;
  onLoad?: () => void;
}

const ImgUpload = ({ onFileChange, onLoad }: ImgUploadProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { screenWidth } = useUserDevice();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      onFileChange(file);
    }
  };
  return (
    <Container>
      <img
        src={selectedFile ? URL.createObjectURL(selectedFile) : uploadImg}
        alt='Preview'
        style={{ width: screenWidth, cursor: "pointer" }}
        onClick={() => inputRef.current?.click()}
        onLoad={onLoad}
      />
      <input
        type='file'
        accept='image/*'
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={inputRef}
      />
    </Container>
  );
};

export default ImgUpload;
