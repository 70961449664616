import {
  onAuthStateChanged,
  signInAnonymously,
  User,
  getAuth,
  deleteUser,
} from "firebase/auth";
import { auth } from "./firebase";

type handleAuth = {
  handleAuthChange?: (user: User) => void;
  handleAuthFail?: () => void;
};

export const useAuth = () => {
  const authenticate = async () => await signInAnonymously(auth);
  const authListener = (handleAuth: handleAuth) =>
    onAuthStateChanged(auth, (user) => {
      const { handleAuthChange, handleAuthFail } = handleAuth;
      if (user) {
        handleAuthChange && handleAuthChange(user);
      } else {
        handleAuthFail && handleAuthFail();
      }
    });
  return { authenticate, authListener };
};

export const signOut = () => {
  const user = getAuth().currentUser;
  if (user) {
    return deleteUser(user);
  } else {
    console.log("auth sign out error: no user");
    return null;
  }
};
