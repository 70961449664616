import { Box, Button, SxProps, Typography } from "@mui/material";
import ScoreBoardItem from "./ScoreBoardItem";
import { PlayerAvatarProps } from "components/General/PlayerAvatar";
import { ImgType, useGameContext } from "GameContext";
import theme, { animations } from "theme";
import { useFirestore } from "@services/firebase/firestore";
import ImgDisplay from "components/General/ImgDisplay";
import { GameState } from "components/General/GameContainer";
import { useUserDevice } from "Hooks/useUserDevice";
import { get } from "http";

interface ScoreBoardProps {
  variant: "CAPTION" | "IMG";
  nextState: GameState;
  nextRound: GameState;
}

type RoundPoints = { roundPoints: number; totalPoints: number };

const ScoreBoard = ({ variant, nextState, nextRound }: ScoreBoardProps) => {
  const { screenWidth } = useUserDevice();
  const ROUND_TIME: number = parseInt(
    process.env.REACT_APP_ROUND_TIME || "90000"
  );
  const { setRoom } = useFirestore();
  const {
    game: { players, judge, isJudge, roomCode },
  } = useGameContext();

  const playerAvatarList: { [uid: string]: PlayerAvatarProps[] } = {};
  const playerRoundPoints: { [uid: string]: RoundPoints } = {};
  const judgeName =
    (players && judge && players[judge] && players[judge].name) || "judge";

  if (players) {
    Object.keys(players).forEach((player) => {
      const votedPlayer = players[player].vote;
      const roundPoints = player === judge ? 100 : 70;
      const playerAvatarProps: PlayerAvatarProps = {
        name: players[player].name,
        icon: "PERSON",
        color:
          player === judge
            ? theme.palette.success.main
            : theme.palette.secondary.main,
      };

      playerAvatarList[votedPlayer]
        ? playerAvatarList[votedPlayer].push(playerAvatarProps)
        : (playerAvatarList[votedPlayer] = [playerAvatarProps]);

      //Create current player point if not exists
      if (!playerRoundPoints[player]) {
        playerRoundPoints[player] = {
          roundPoints: 0,
          totalPoints: players[player].totalPoints || 0,
        };
      }

      //create voted player points if not exists
      if (!playerRoundPoints[votedPlayer]) {
        playerRoundPoints[votedPlayer] = {
          roundPoints: 0,
          totalPoints: players[votedPlayer].totalPoints || 0,
        };
      }

      playerRoundPoints[votedPlayer].roundPoints += roundPoints;
      playerRoundPoints[votedPlayer].totalPoints += roundPoints;
    });
  }

  const itemContent = (caption: string, playerId: string) =>
    variant === "CAPTION" ? (
      <Box sx={styles.captionBox}>
        <Typography
          sx={styles.centerText}
          variant={playerId === judge ? "h4" : "h6"}
          color={playerId === judge ? "secondary" : "primary"}
        >
          {playerId === judge ? "Judge" : caption}
        </Typography>
      </Box>
    ) : (
      <Box>
        {playerId === judge ? (
          <Typography sx={styles.centerText} variant='h4' color='secondary'>
            Judge
          </Typography>
        ) : players ? (
          <Box sx={styles.imgDisplay}>
            <ImgDisplay
              imgURI={players[playerId].imgURI}
              imgType={players[playerId].imgType}
              width={130}
            />
          </Box>
        ) : null}
      </Box>
    );

  const clearRound = () => {
    if (players && roomCode && judge) {
      const nextJudge = Object.keys(players).find(
        (player) => player !== judge && !players[player].hasJudged
      );

      setRoom(roomCode, {
        state: nextJudge ? nextState : nextRound,
        judge: nextJudge ? nextJudge : Object.keys(players)[0],
        timerEnd: Date.now() + ROUND_TIME,
        players: Object.keys(players).reduce(
          (acc, player) => ({
            ...acc,
            [player]: {
              ...players[player],
              caption: "",
              vote: "",
              totalPoints:
                playerRoundPoints[player].totalPoints ||
                players[player].totalPoints,
              imgURI: "",
              hasJudged: !nextJudge
                ? false
                : player === judge
                ? true
                : players[player].hasJudged,
            },
          }),
          {}
        ),
      });
    }
  };

  const getRoundContent = (
    contentUri: string,
    variant: string,
    imgType?: ImgType
  ) => {
    if (variant === "IMG") {
      return (
        <Typography variant='h3' color='primary' sx={styles.captionText}>
          {contentUri}
        </Typography>
      );
    } else {
      return (
        <Box
          sx={{
            borderStyle: "solid",
            borderWidth: "3px",
            borderColor: theme.palette.primary.main,
          }}
        >
          <ImgDisplay
            imgURI={contentUri}
            imgType={imgType || "GIF"}
            width={screenWidth / 2}
          />
        </Box>
      );
    }
  };

  return (
    <Box sx={styles.bodyBox}>
      <Typography variant='h1' color='secondary'>
        Score
      </Typography>
      {players &&
        judge &&
        getRoundContent(
          variant === "CAPTION"
            ? players[judge].imgURI
            : players[judge].caption,
          variant,
          players[judge].imgType
        )}
      <Box sx={styles.scoreBoardItemList}>
        {players &&
          Object.keys(playerRoundPoints)
            ?.sort(
              (player1, player2) =>
                playerRoundPoints[player2].totalPoints -
                playerRoundPoints[player1].totalPoints
            )
            .map((player) => (
              <ScoreBoardItem
                name={players[player].name}
                totalPoints={playerRoundPoints[player].totalPoints}
                roundPoints={playerRoundPoints[player].roundPoints}
                content={itemContent(players[player].caption, player)}
                voterAvatars={playerAvatarList[player] || []}
              />
            ))}
      </Box>
      <Box sx={styles.continueBox}>
        {isJudge ? (
          <Box sx={styles.continueBox}>
            <Typography
              variant='h5'
              color='secondary'
              sx={styles.judgeContinue}
            >
              Click{" "}
              <span style={{ color: theme.palette.primary.main }}>
                continue
              </span>{" "}
              to move on to the next round.
            </Typography>
            <Button color='secondary' variant='contained' onClick={clearRound}>
              Continue
            </Button>
          </Box>
        ) : (
          <Typography variant='h5' color='secondary' sx={styles.waitText}>
            Waiting for{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {judgeName}
            </span>{" "}
            to continue
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  scoreBoardItemList: {
    width: "100%",
  },
  centerText: {
    textAlign: "center",
  },
  continueBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
  },
  captionText: {
    textAlign: "center",
  },
  waitText: {
    textAlign: "center",
    ...animations.pulse,
  },
  judgeContinue: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
    ...animations.pulse,
  },
  imgDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ScoreBoard;
