import { SxProps, createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#57C5C9",
    },
    error: {
      main: "#DF2935",
    },
    success: {
      main: "#5AFF15",
    },
    info: {
      main: "#9E9E9E",
    },
    action: {
      disabled: "#9E9E9E",
      disabledBackground: "#9E9E9E",
    },
  },
  typography: {
    button: {
      fontSize: 20,
      fontFamily: "Squada One",
    },
    fontFamily: ["Squada One", "Roboto"].join(","),
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "white",
          "--TextField-brandBorderHoverColor": "white",
          "--TextField-brandBorderFocusedColor": "white",
          "& label.Mui-focused": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
  },
});

export const animations: Record<string, SxProps> = {
  pulse: {
    "@keyframes pulse": {
      "0%": {
        opacity: 0.3,
      },
      "50%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0.3,
      },
    },
    animation: "pulse 4s ease infinite",
  },
};

export default theme;
