import { Box, SxProps, Typography } from "@mui/material";
import { useFirestore } from "@services/firebase/firestore";
import { getRandomGif } from "@services/giphy/giphy";
import { ImgType, Player, useGameContext } from "GameContext";
import CollapsableAlert from "components/General/CollapsableAlert";
import PlayerAggregate from "components/General/PlayerAggregate";
import Timer from "components/General/Timer";
import UploadContainer from "components/Round1/UploadContainer";
import { useEffect, useState } from "react";
import theme, { animations } from "theme";
import RoundHeader from "components/General/RoundHeader";

const Round2Img = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { setRoom } = useFirestore();

  const {
    game: {
      players,
      judge,
      roomCode,
      currentUser,
      isSpectator,
      isJudge,
      timerEnd,
    },
  } = useGameContext();

  useEffect(() => {
    if (players && currentUser && players[currentUser]["imgURI"]) {
      setSubmitted(true);
    }
  }, []);

  useEffect(() => {
    if (isJudge && players) {
      const allUploaded = Object.keys(players).every(
        (player) => player === judge || players[player].imgURI
      );

      if (allUploaded) {
        setRoom(roomCode!, { state: "ROUND2_VOTE" });
      }
    }
  }, [isJudge, judge, players]);

  if (!players || !judge || !timerEnd) {
    //TODO: HANDLE ERROR STATE
    return <div>ERROR</div>;
  }

  const onTimerComplete = async () => {
    if (isJudge) {
      let randomGifPlayers: { [uid: string]: Partial<Player> } = {};

      for (const key in players) {
        if (!players[key].imgURI && key !== judge) {
          const randomGif = await getRandomGif();
          randomGifPlayers[key] = {
            imgURI: randomGif.data.id.toString(),
            imgType: "GIF",
          };
        }
      }
      setRoom(roomCode!, {
        state: "ROUND2_VOTE",
        players: { ...players, ...randomGifPlayers },
      });
    }
  };

  const setImgURI = (imgURI: string, imgType: ImgType) => {
    if (roomCode && currentUser) {
      setRoom(roomCode, {
        players: { [currentUser]: { imgURI: imgURI, imgType: imgType } },
      });
      setSubmitted(true);
    } else {
      setErrorMessage("Error fetching game context please refresh the page");
    }
  };

  const getContent = () => {
    if (submitted || isSpectator || isJudge) {
      return (
        <Box sx={styles.captionBox}>
          <Box sx={styles.playerAggBox}>
            <PlayerAggregate field='imgURI' />
          </Box>
          <Typography variant='h5' color='secondary' sx={styles.waitText}>
            Waiting for players to submit their images...
          </Typography>
        </Box>
      );
    } else {
      return (
        <UploadContainer
          caption={players[judge].caption}
          onUpload={setImgURI}
          onError={setErrorMessage}
        />
      );
    }
  };

  return (
    <Box sx={styles.bodyBox}>
      <RoundHeader>
        <>
          <span style={{ color: theme.palette.primary.main }}>
            {players[judge].name}{" "}
          </span>
          is the judge
        </>
      </RoundHeader>
      <Timer timerEnd={timerEnd} onComplete={onTimerComplete} />
      {getContent()}
      {errorMessage && (
        <Box sx={styles.errorMessage}>
          <CollapsableAlert
            severity='error'
            text={errorMessage}
            onClose={() => setErrorMessage("")}
          />
        </Box>
      )}
    </Box>
  );
};
const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorMessage: {
    marginTop: theme.spacing(3),
  },
  captionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  waitText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    ...animations.pulse,
  },
};

export default Round2Img;
