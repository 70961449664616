import { Box, Button, SxProps, Typography } from "@mui/material";
import { signOut } from "@services/firebase/authentication";
import { useGameContext } from "GameContext";
import { useUserDevice } from "Hooks/useUserDevice";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import theme, { animations } from "theme";

const FinalScore = () => {
  const navigate = useNavigate();
  const { screenWidth } = useUserDevice();
  const {
    game: { players },
    setGame,
  } = useGameContext();
  if (!players) {
    return <div>ERROR</div>;
  }

  const sortedPlayers = Object.keys(players)?.sort(
    (player1, player2) =>
      players[player2].totalPoints - players[player1].totalPoints
  );

  const signOutPlayer = () => {
    signOut()?.then(() => {
      setGame({});
      navigate("/");
    });
  };

  return (
    <Box sx={styles.bodyBox}>
      <Typography variant='h1' color='secondary' sx={styles.winnerText}>
        Winner:{" "}
        <span style={{ color: theme.palette.primary.main }}>
          {players[sortedPlayers[0]].name}
        </span>
      </Typography>
      <ConfettiExplosion
        duration={3000}
        particleCount={200}
        width={screenWidth}
        colors={[
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.error.main,
          theme.palette.success.main,
        ]}
      />
      <Box>
        {sortedPlayers.map((player) => (
          <Box sx={styles.totalPointsBox}>
            <Box sx={styles.pointsBox}>
              <Typography
                variant='h3'
                color='primary'
                sx={{ marginRight: theme.spacing(1) }}
              >
                {players[player].totalPoints}
              </Typography>
              <Typography variant='h5' color='primary'>
                pts:
              </Typography>
            </Box>
            <Box sx={styles.nameBox}>
              <Typography variant='h3' color='secondary' sx={styles.nameText}>
                {players[player].name}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Typography variant='h5' color='secondary' sx={styles.homeText}>
        Thanks for playing! To{" "}
        <span style={{ color: theme.palette.primary.main }}>restart</span>,
        click the{" "}
        <span style={{ color: theme.palette.primary.main }}>return home</span>{" "}
        button below.
      </Typography>
      <Button
        sx={styles.homeButton}
        color='secondary'
        variant='contained'
        onClick={signOutPlayer}
      >
        Return Home
      </Button>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  winnerText: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  totalPointsBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  pointsBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
  },
  nameBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
  },
  nameText: {
    marginLeft: theme.spacing(1),
  },
  homeButton: {
    marginTop: theme.spacing(3),
  },
  homeText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    ...animations.pulse,
  },
};

export default FinalScore;
