import {
  Container,
  Grid,
  Button,
  SxProps,
  Typography,
  Fade,
  Box,
} from "@mui/material";
import { useGameContext } from "../../GameContext";
import { useFirestore } from "@services/firebase/firestore";
import { useNavigate } from "react-router-dom";
import theme, { animations } from "theme";
import { useEffect, useState } from "react";
import RoomCode from "components/General/RoomCode";

const Lobby = () => {
  const {
    game: { isHost, players, roomCode, host },
  } = useGameContext();
  const { setRoom } = useFirestore();
  const navigate = useNavigate();
  const [playerIds, setPlayerIds] = useState<string[]>(
    Object.keys(players || {})
  );
  const ROUND_TIME: number = parseInt(
    process.env.REACT_APP_ROUND_TIME || "90000"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (players) {
      Object.keys(players).forEach((player) => {
        if (!playerIds.includes(player)) {
          setPlayerIds((prevIds) => [...prevIds, player]);
        }
      });
    }
  }, [players]);

  if (!players || !roomCode || !host) {
    navigate("./");
    return <div>GenericErrror</div>;
  }

  const hostName = players[host].name;

  const waitText = isHost ? (
    <span>
      Press <span style={{ color: theme.palette.primary.main }}>start</span>{" "}
      when all players have joined.
    </span>
  ) : (
    <span>
      Waiting for{" "}
      <span style={{ color: theme.palette.primary.main }}>{hostName}</span> to
      start the game...
    </span>
  );

  const goToRound1 = () => {
    const nextJudge = Object.keys(players)[0];
    const currentTime = Date.now();
    const round1EndTime = currentTime + ROUND_TIME;
    setRoom(roomCode, {
      state: "ROUND1",
      timerEnd: round1EndTime,
      judge: nextJudge,
    });
  };

  return (
    <Container sx={styles.bodyContainer}>
      <Typography variant='h1' color='secondary'>
        Lobby
      </Typography>
      <RoomCode roomCode={roomCode} />
      <Container sx={styles.grid}>
        <Grid container spacing={2}>
          {playerIds.map((playerId, index) => (
            <Grid item xs={6} key={index}>
              <Fade in appear timeout={1000}>
                <Container sx={styles.gridItem}>
                  <Typography variant='h3' color='primary' sx={styles.name}>
                    {players[playerId].name}
                  </Typography>
                </Container>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box sx={animations.pulse}>
        <Typography variant='h5' color='secondary' sx={styles.waitText}>
          {waitText}
        </Typography>
      </Box>
      {isHost && (
        <Button
          color='secondary'
          variant='contained'
          data-testid='start-button'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            goToRound1();
          }}
        >
          Start
        </Button>
      )}
    </Container>
  );
};

const styles: Record<string, SxProps> = {
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  grid: {
    margin: "2rem",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderBottom: "1px solid white",
  },
  name: {
    textAlign: "center",
  },
  waitText: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
};

export default Lobby;
