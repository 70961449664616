import { Box, SxProps, Typography } from "@mui/material";
import { useGameContext } from "GameContext";
import ImgDisplay from "components/General/ImgDisplay";
import { useEffect, useState } from "react";
import theme, { animations } from "theme";
import { useFirestore } from "@services/firebase/firestore";
import SlideText from "components/General/SlideCaptions";
import VoteBox from "components/General/VoteBox";
import RoundHeader from "components/General/RoundHeader";

type Caption = { caption: string; user: string };

const Round1Vote = () => {
  const [captions, setCaptions] = useState<Caption[]>([]);
  const [captionIndex, setCaptionIndex] = useState(0);
  const [voted, setVoted] = useState(false);
  const [slideDirection, setSlideDirection] = useState<"left" | "right">(
    "right"
  );
  const { setRoom } = useFirestore();
  const {
    game: {
      players,
      currentUser,
      roomCode,
      isSpectator,
      isHost,
      isJudge,
      judge,
    },
  } = useGameContext();

  useEffect(() => {
    setCaptions(
      Object.entries(players!)
        .filter(([user, player]) => !!player.caption && user !== currentUser)
        .map(([user, player]) => ({ caption: player.caption, user: user }))
    );
  }, [currentUser]);

  useEffect(() => {
    if (isHost && players) {
      const allVoted = Object.values(players).every((player) => player.vote);

      if (allVoted) {
        setRoom(roomCode!, { state: "ROUND1_SCORE" });
      }
    }
    if (players && currentUser && players[currentUser].vote) {
      setVoted(true);
    }
  }, [currentUser, isHost, players, roomCode, setRoom]);

  const submitVote = () => {
    const votedPlayerId = captions[captionIndex].user;
    if (roomCode && currentUser) {
      setRoom(roomCode, {
        players: {
          [currentUser]: { vote: votedPlayerId },
        },
      }).catch((err) => console.log(err));
    }
  };

  if (!players || !judge) {
    //TODO: HANDLE ERROR STATE
    return <div>ERROR</div>;
  }

  return (
    <Box sx={styles.bodyBox}>
      <RoundHeader>
        <>
          <span style={{ color: theme.palette.primary.main }}>
            {players[judge].name}{" "}
          </span>
          is the judge
        </>
      </RoundHeader>
      <ImgDisplay
        imgURI={players[judge].imgURI}
        imgType={players[judge].imgType}
      />
      {captions.map((caption, index) => (
        <SlideText
          inCondition={captionIndex === index}
          direction={slideDirection}
          text={caption.caption}
          sx={
            captionIndex === index ? styles.captionText : styles.hiddenCaption
          }
        />
      ))}
      <VoteBox
        showWaiting={voted || !!isSpectator}
        submitVote={submitVote}
        disableGoLeft={captionIndex === 0}
        disableGoRight={captionIndex === captions.length - 1}
        onLeftClick={() => {
          setSlideDirection("right");
          setCaptionIndex(captionIndex - 1);
        }}
        onRightClick={() => {
          setSlideDirection("left");
          setCaptionIndex(captionIndex + 1);
        }}
      />
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  captionBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
  },
  iconBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  voteBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  hiddenCaption: {
    display: "none",
  },
  captionText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  waitText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    ...animations.pulse,
  },
};

export default Round1Vote;
