import { Box, Button, SxProps, Typography } from "@mui/material";
import { useFirestore } from "@services/firebase/firestore";
import { useGameContext } from "GameContext";
import ImgDisplay from "components/General/ImgDisplay";
import PlayerAggregate from "components/General/PlayerAggregate";
import TextInput from "components/General/TextInput";
import React, { useEffect, useState } from "react";
import theme, { animations } from "theme";

interface CaptionImgProps {
  isSpectator: boolean;
  isJudge: boolean;
  timerEnd: number;
  judgeName: string;
}

const CaptionImg = ({ isJudge, isSpectator, judgeName }: CaptionImgProps) => {
  const [caption, setCaption] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { setRoom } = useFirestore();
  const {
    game: { currentUser, roomCode, players, judge },
  } = useGameContext();

  useEffect(() => {
    if (players && currentUser && players[currentUser]["caption"]) {
      setSubmitted(true);
    }
  }, []);

  const handleSubmit = () => {
    if (roomCode) {
      setRoom(roomCode, {
        players: { [currentUser!]: { caption: caption } },
      }).then(() => {
        setSubmitted(true);
      });
    }
  };

  const getCaptionBox = () => {
    if (submitted || isSpectator || isJudge) {
      return (
        <Box sx={styles.captionBox}>
          <Box sx={styles.playerAggBox}>
            <PlayerAggregate field='caption' />
          </Box>
          <Typography variant='h5' color='secondary' sx={styles.waitText}>
            Waiting for players to submit their captions...
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box sx={styles.captionBox}>
          <TextInput label='Caption' multiline onChange={setCaption} />
          <Button
            sx={styles.submitButton}
            color='secondary'
            variant='contained'
            data-testid='start-button'
            onClick={handleSubmit}
          >
            submit
          </Button>
        </Box>
      );
    }
  };

  if (!players || !judge) {
    //TODO: HANDLE ERROR STATE
    return <div>ERROR</div>;
  }

  return (
    <Box sx={styles.bodyBox}>
      {!isSpectator && !isJudge && (
        <Typography variant='h6' color='secondary' sx={styles.centerText}>
          <span style={{ color: theme.palette.primary.main }}>{judgeName}</span>{" "}
          is the judge. You have until the timer runs out to come up with a{" "}
          <span style={{ color: theme.palette.primary.main }}>caption</span>{" "}
          that they will think is the{" "}
          <span style={{ color: theme.palette.primary.main }}>best!</span>
        </Typography>
      )}
      <Box sx={styles.imgComponent}>
        <ImgDisplay
          imgURI={players[judge].imgURI}
          imgType={players[judge].imgType}
        />
      </Box>
      {getCaptionBox()}
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerText: {
    textAlign: "center",
  },
  imgComponent: {
    marginTop: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  waitText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    ...animations.pulse,
  },
  captionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  playerAggBox: {
    marginTop: theme.spacing(3),
  },
};

export default CaptionImg;
