import { Box, Button, SxProps, Typography } from "@mui/material";
import { useFirestore } from "@services/firebase/firestore";
import { useGameContext } from "GameContext";
import TextInput from "components/General/TextInput";
import { useState } from "react";
import theme from "theme";

const UploadCaption = () => {
  const ROUND_TIME: number = parseInt(
    process.env.REACT_APP_ROUND_TIME || "90000"
  );
  const [caption, setCaption] = useState("");
  const { setRoom } = useFirestore();

  const {
    game: { roomCode, currentUser },
  } = useGameContext();

  const handleSubmit = () => {
    if (roomCode) {
      setRoom(roomCode, {
        state: "ROUND2_IMG",
        timerEnd: Date.now() + ROUND_TIME,
        players: { [currentUser!]: { caption: caption } },
      });
    }
  };

  return (
    <Box sx={styles.bodyBox}>
      <Typography variant='h5' color='secondary' sx={styles.centerText}>
        You have until the timer runs out to create a{" "}
        <span style={{ color: theme.palette.primary.main }}>caption</span> for
        other players to upload a{" "}
        <span style={{ color: theme.palette.primary.main }}>photo</span> or{" "}
        <span style={{ color: theme.palette.primary.main }}>gif</span> that
        matches.
      </Typography>
      <TextInput label='Caption' multiline onChange={setCaption} />
      <Button
        sx={styles.submitButton}
        color='secondary'
        variant='contained'
        onClick={handleSubmit}
      >
        submit
      </Button>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerText: {
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
};

export default UploadCaption;
