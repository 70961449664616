import {
  Box,
  Button,
  CircularProgress,
  Grow,
  Switch,
  SxProps,
  Typography,
} from "@mui/material";
import ImgUpload from "components/General/ImgUpload";
import { useEffect, useRef, useState } from "react";
import GifGrid from "components/General/GifGrid";
import theme from "theme";
import { useUserDevice } from "Hooks/useUserDevice";
import { buildStorageRef, uploadFile } from "@services/firebase/storage";
import { ImgType, useGameContext } from "GameContext";

interface UploadContainerProps {
  onUpload: (imgUri: string, imgType: ImgType) => void;
  onError: (error: string) => void;
  caption?: string;
}

const UploadContainer = ({
  caption,
  onUpload,
  onError,
}: UploadContainerProps) => {
  const [imgId, setImgId] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [imgType, setImgType] = useState<ImgType>("PHOTO_LIBRARY");
  const [initialLoad, setInitialLoad] = useState(false);
  const [componentHeight, setComponentHeight] = useState(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const imgUploadRef = useRef<HTMLDivElement>(null);
  const { screenWidth } = useUserDevice();
  const {
    game: { state, roomCode, currentUser },
  } = useGameContext();

  const isPhotoLibrary = imgType === "PHOTO_LIBRARY";

  useEffect(() => {
    if (imgUploadRef.current && isPhotoLibrary) {
      setComponentHeight(imgUploadRef.current.clientHeight);
    }
  }, [initialLoad, isPhotoLibrary]);

  const handleUpload = async () => {
    setUploading(true);
    try {
      if (
        imgType === "PHOTO_LIBRARY" &&
        file &&
        roomCode &&
        currentUser &&
        state
      ) {
        const downloadURL = await uploadFile(
          file,
          buildStorageRef(roomCode, currentUser, state, file.name)
        );
        onUpload(downloadURL, "PHOTO_LIBRARY");
      } else if (imgType === "GIF" && imgId) {
        onUpload(imgId, "GIF");
      } else {
        onError("Please upload an image/gif");
      }
    } catch (e) {
      onError("Error uploading image/gif");
    } finally {
      setUploading(false);
    }
  };

  const handleSwitch = (e: React.ChangeEvent, checked: boolean) => {
    setImgType(checked ? "GIF" : "PHOTO_LIBRARY");
  };

  return (
    <Box
      sx={{
        ...styles.bodyContainer,
        height: imgUploadRef.current?.clientHeight || 50,
      }}
    >
      <Box sx={styles.switchContainer}>
        <Typography
          variant='h5'
          color={!isPhotoLibrary ? "primary" : "secondary"}
          sx={!isPhotoLibrary ? styles.notSelectedImgType : null}
        >
          Photo Library
        </Typography>
        <Switch
          size='medium'
          color='secondary'
          checked={!isPhotoLibrary}
          onChange={handleSwitch}
          inputProps={{ "aria-label": "controlled" }}
          sx={styles.switch}
        />
        <Typography
          variant='h5'
          color={isPhotoLibrary ? "primary" : "secondary"}
          sx={isPhotoLibrary ? styles.notSelectedImgType : null}
        >
          Search Gifs
        </Typography>
      </Box>
      <Box sx={styles.contentDisplay}>
        <Grow in={isPhotoLibrary}>
          <Box
            sx={!isPhotoLibrary ? styles.hidden : styles.imgUpload}
            ref={imgUploadRef}
          >
            <ImgUpload
              onLoad={() => setInitialLoad(true)}
              onFileChange={(file) => {
                setFile(file);
              }}
            />
          </Box>
        </Grow>
        <Grow in={!isPhotoLibrary}>
          <Box sx={isPhotoLibrary ? styles.hidden : styles.gifStyles}>
            <GifGrid
              height={componentHeight}
              width={screenWidth}
              setImgId={setImgId}
            />
          </Box>
        </Grow>
      </Box>
      {caption && (
        <Typography variant='h5' color='primary' sx={styles.captionText}>
          {caption}
        </Typography>
      )}
      <Box>
        {!uploading && initialLoad && (
          <Button
            color='secondary'
            variant='contained'
            onClick={handleUpload}
            disabled={uploading}
          >
            Upload
          </Button>
        )}
        {uploading && <CircularProgress color='secondary' />}
      </Box>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  imgUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  switchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  notSelectedImgType: {
    opacity: 0.5,
  },
  captionText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  switch: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "white",
    },
  },
};

export default UploadContainer;
