import { Box, IconButton, SxProps, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";

interface RoomCodeProps {
  roomCode: string;
  variant?: "h5" | "h6";
  grayScale?: boolean;
}

const RoomCode = ({
  roomCode,
  variant = "h5",
  grayScale = false,
}: RoomCodeProps) => {
  const [copied, setCopied] = useState(false);
  const copyRoomCode = () => {
    navigator.clipboard.writeText(roomCode || "");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Box sx={styles.roomCodeBox}>
      {!grayScale && (
        <Typography variant={variant} color={"primary"} marginRight={1}>
          Room:
        </Typography>
      )}
      <Typography
        variant={variant}
        color={grayScale ? "grey.500" : "secondary"}
        data-testid='room-code-text'
      >
        {`${roomCode}`}
      </Typography>
      <IconButton size='small' onClick={copyRoomCode}>
        {!copied ? (
          <ContentCopyIcon
            color={grayScale ? "info" : "primary"}
            fontSize='small'
          />
        ) : (
          <CheckIcon color='success' fontSize='small' />
        )}
      </IconButton>
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  roomCodeBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default RoomCode;
