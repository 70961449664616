import Lobby from "components/Lobby/Lobby";
import { useGameContext } from "GameContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "./PageWrapper";
import Round1 from "components/Round1/Round1";
import Round1Caption from "components/Round1/Round1Caption";
import Round1Vote from "components/Round1/Round1Vote";
import ScoreBoard from "components/ScoreBoard/ScoreBoard";
import Round2 from "components/Round2/Round2";
import Round2Img from "components/Round2/Round2Img";
import Round2Vote from "components/Round2/Round2Vote";
import FinalScore from "./FinalScore";

export type GameState =
  | "LOBBY"
  | "ROUND1"
  | "ROUND1_CAPTION"
  | "ROUND1_VOTE"
  | "ROUND1_SCORE"
  | "ROUND2"
  | "ROUND2_IMG"
  | "ROUND2_VOTE"
  | "ROUND2_SCORE"
  | "FINAL_SCORE";
const GameContainer = () => {
  const {
    game: { state, roomCode },
  } = useGameContext();
  const { currentUser } = useGameContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const renderGameState = () => {
    switch (state) {
      case "LOBBY":
        return <Lobby />;
      case "ROUND1":
        return <Round1 />;
      case "ROUND1_CAPTION":
        return <Round1Caption />;
      case "ROUND1_VOTE":
        return <Round1Vote />;
      case "ROUND1_SCORE":
        return (
          <ScoreBoard variant='CAPTION' nextState='ROUND1' nextRound='ROUND2' />
        );
      case "ROUND2":
        return <Round2 />;
      case "ROUND2_IMG":
        return <Round2Img />;
      case "ROUND2_VOTE":
        return <Round2Vote />;
      case "ROUND2_SCORE":
        return (
          <ScoreBoard
            variant='IMG'
            nextState='ROUND2'
            nextRound='FINAL_SCORE'
          />
        );
      case "FINAL_SCORE":
        return <FinalScore />;
      default:
        //TODO: refresh page if this screen reached
        return <div>Default</div>;
    }
  };

  return (
    <PageWrapper roomCode={roomCode} gameState={state}>
      {renderGameState()}
    </PageWrapper>
  );
};

export default GameContainer;
