import { Box, Button, IconButton, SxProps, Typography } from "@mui/material";
import PlayerAggregate from "./PlayerAggregate";
import theme, { animations } from "theme";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

interface VoteBoxProps {
  showWaiting: boolean;
  submitVote: () => void;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  disableGoLeft?: boolean;
  disableGoRight?: boolean;
}

const VoteBox = ({
  showWaiting,
  submitVote,
  onLeftClick,
  onRightClick,
  disableGoLeft,
  disableGoRight,
}: VoteBoxProps) => {
  return (
    <Box sx={styles.bodyBox}>
      <Box sx={styles.captionBox}>
        <IconButton
          size='large'
          onClick={onLeftClick}
          disabled={disableGoLeft}
          color='secondary'
        >
          <Box sx={styles.iconBox}>
            <ArrowCircleLeftIcon fontSize='large' />
            <Typography variant='h6'>Prev</Typography>
          </Box>
        </IconButton>
        <Box sx={styles.voteBox}>
          {showWaiting ? (
            <Box>
              <PlayerAggregate field='vote' />
            </Box>
          ) : (
            <Button variant='contained' color='secondary' onClick={submitVote}>
              Vote
            </Button>
          )}
        </Box>
        <IconButton
          size='large'
          color='secondary'
          onClick={onRightClick}
          disabled={disableGoRight}
        >
          <Box sx={styles.iconBox}>
            <ArrowCircleRightIcon fontSize='large' />
            <Typography variant='h6'>Next</Typography>
          </Box>
        </IconButton>
      </Box>
      {showWaiting && (
        <Typography variant='h5' color='secondary' sx={styles.waitText}>
          Waiting for players to submit their votes...
        </Typography>
      )}
    </Box>
  );
};

const styles: Record<string, SxProps> = {
  bodyBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  captionBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  iconBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  voteBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  waitText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    ...animations.pulse,
  },
};

export default VoteBox;
