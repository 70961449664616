import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
} from "@mui/material";
import { useAuth } from "@services/firebase/authentication";
import { useFirestore } from "@services/firebase/firestore";
import CollapsableAlert from "components/General/CollapsableAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/svg/logo.svg";
import { PlayerType, useGameContext } from "../../GameContext";
import PageWrapper from "../General/PageWrapper";
import TextInput from "../General/TextInput";
import theme from "theme";
import { UserCredential } from "firebase/auth";

const Home = () => {
  const [playerType, setPlayerType] = useState<PlayerType>("UNKNOWN");
  const [name, setName] = useState<string>("");
  const [roomCode, setRoomCode] = useState<string>("");
  const [alertText, setAlertText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { authenticate } = useAuth();
  const { setRoom, setUser, getRoom } = useFirestore();
  const navigate = useNavigate();
  const { game, currentUser } = useGameContext();

  useEffect(() => {
    if (currentUser && game.roomCode) {
      navigate("./play");
    }
  }, [currentUser, game.roomCode, navigate]);

  //generate random 4 letter string
  const generateRoomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const getRoomData = (user: UserCredential) => {
    switch (playerType) {
      case "HOST":
        return {
          host: user.user.uid,
          state: "LOBBY",
          players: {
            [user.user.uid]: {
              name: name,
              hasJudged: false,
              totalPoints: 0,
            },
          },
        };
      case "PLAYER":
        return {
          state: "LOBBY",
          players: {
            [user.user.uid]: {
              name: name,
              hasJudged: false,
              totalPoints: 0,
            },
          },
        };
      case "SPECTATOR":
        return {
          spectators: {
            [user.user.uid]: {
              name: name,
            },
          },
        };
      case "UNKNOWN":
        return null;
    }
  };

  const authenticateUser = async () => {
    const roomCodeDoc = playerType === "HOST" ? generateRoomCode() : roomCode;
    const user = await authenticate();
    const roomData = getRoomData(user);
    if (roomData) {
      await setUser(user.user.uid, {
        host: playerType === "HOST",
        spectator: playerType === "SPECTATOR",
        name: name,
        roomCode: roomCodeDoc,
      });

      await setRoom(roomCodeDoc, roomData);
    } else {
      setLoading(false);
      setAlertText("Error creating player");
    }
  };

  const validateRoom = () => {
    getRoom(
      roomCode,
      (data) => {
        if (data.state !== "LOBBY") {
          setPlayerType("SPECTATOR");
        } else {
          setAlertText("");
          setLoading(false);
          authenticateUser();
        }
      },
      () => {
        setLoading(false);
        setAlertText("Invalid room code");
      }
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!name && !roomCode) {
      setLoading(false);
      setAlertText("Please enter a name and room code");
      return;
    }
    if (playerType === "HOST") {
      authenticateUser();
    } else if (playerType === "PLAYER") {
      validateRoom();
    }
  };

  const onCloseDialog = () => setPlayerType("UNKNOWN");

  return (
    <PageWrapper>
      <img src={logo} alt='Logo' />
      <Container sx={styles.buttonContainer}>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => setPlayerType("HOST")}
          data-testid='host-game-button'
        >
          Host Game
        </Button>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => setPlayerType("PLAYER")}
          data-testid='join-game-button'
        >
          Join Game
        </Button>
      </Container>
      <Container sx={styles.inputContainer}>
        {playerType === "PLAYER" && (
          <TextInput
            autoFocus
            label='Enter room code'
            onChange={(text) => setRoomCode(text.toUpperCase())}
            style={{ textTransform: "uppercase" }}
          />
        )}
        {playerType !== "UNKNOWN" && (
          <Container sx={styles.submitContainer}>
            <TextInput label='Enter your name' onChange={setName} />
            <Button
              sx={{ marginTop: "2rem" }}
              color='secondary'
              variant='contained'
              onClick={() => {
                handleSubmit();
              }}
              data-testid='submit-button'
            >
              Submit
            </Button>
            <Dialog open={playerType === "SPECTATOR"} onClose={onCloseDialog}>
              <Box
                sx={{
                  backgroundColor: "black",
                  borderStyle: "solid",
                  borderColor: "white",
                }}
              >
                <DialogTitle sx={{ color: theme.palette.primary.main }}>
                  This game has already started
                </DialogTitle>
                <DialogContent>
                  <DialogContentText sx={{ color: theme.palette.primary.main }}>
                    Would you like to join as a spectator?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCloseDialog} color='error'>
                    Disagree
                  </Button>
                  {!loading && (
                    <Button
                      onClick={authenticateUser}
                      autoFocus
                      variant='contained'
                      color='secondary'
                    >
                      Agree
                    </Button>
                  )}
                  {loading && <CircularProgress color='secondary' />}
                </DialogActions>
              </Box>
            </Dialog>
            {alertText && (
              <Container sx={styles.alertContainer}>
                <CollapsableAlert
                  severity='error'
                  text={alertText}
                  onClose={() => setAlertText("")}
                />
              </Container>
            )}
          </Container>
        )}
      </Container>
    </PageWrapper>
  );
};

const styles: Record<string, SxProps> = {
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  inputContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  submitContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
  },
  alertContainer: {
    marginTop: "2rem",
  },
};

export default Home;
