import { Box, Slide, SxProps } from "@mui/material";
import { ImgType } from "GameContext";
import ImgDisplay from "./ImgDisplay";

interface SlideImageProps {
  direction: "right" | "left";
  imgURI: string;
  imgType: ImgType;
  inCondition: boolean;
}

const SlideImage = ({
  inCondition,
  direction,
  imgURI,
  imgType,
}: SlideImageProps) => {
  return (
    <Slide in={inCondition} direction={direction}>
      <Box>
        <ImgDisplay imgURI={imgURI} imgType={imgType} />
      </Box>
    </Slide>
  );
};

export default SlideImage;
