// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "rt-game-d8121.firebaseapp.com",
  databaseURL: "https://rt-game-d8121.firebaseio.com",
  projectId: "rt-game-d8121",
  storageBucket: "rt-game-d8121.appspot.com",
  messagingSenderId: "525177692316",
  appId: "1:525177692316:web:0d189eb0b6fefc4b470d59",
  measurementId: "G-9RQNL95G1K",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
